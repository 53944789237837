import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const configland: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: 'HOME',
    icon: 'Home',
    href: '/land/home',
  },
  {
    label: 'BUY CARD',
    icon: 'Buycard',
    href: '/land/buycards',
    disabled: false
  },
  {
    label: 'SINGLE POOL',
    icon: 'Singlepool',
    href: '/land/singlePool',
    disabled: false
  },
  {
    label: 'FUSION POOL',
    icon: 'Fusionpool',
    href: '/land/fusionPool',
    disabled: false
  }, 
  {
    label: 'PIXEL CARD LIST',
    icon: 'Cardlist',
    href: '/land/pixelList',
  },
  {
    label: 'FUSION LIST',
    icon: 'Fusionlist',
    href: '/land/fusionList',
  },
  {
    label: 'MARKET PLACE',
    icon: 'Marketplace',
    href: '/land/pixelMarket',
    disabled: false
  }, 
  // {
  //   label: 'MORE',
  //   icon: 'More',
  //   items: [

  //   ]
  // },
]

export default configland
