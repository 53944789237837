import React, { useState, } from 'react'
import { Flex, Button, Heading, Skeleton, AutoRenewIcon } from '@pixelweb/uikit'
import BigNumber from 'bignumber.js'
import { Token } from 'config/constants/types'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber } from 'utils/formatBalance'
import { useSousHarvest } from 'hooks/useHarvest'
import useToast from 'hooks/useToast'
import Balance from 'components/Balance'
 
interface HarvestActionsProps {
  earnings: BigNumber
  earningToken: Token
  sousId: number
  isBnbPool: boolean
  isLoading?: boolean,
  stakingToken: any,
}


const HarvestActions: React.FC<HarvestActionsProps> = ({
  earnings,
  earningToken,
  sousId,
  isBnbPool,
  isLoading = false,
}) => {
  const { t } = useTranslation()
  const [pendingTx, setPendingTx] = useState(false)
  const { toastSuccess, toastError } = useToast()
  const { onReward } = useSousHarvest(sousId, isBnbPool)
  const earningTokenBalance = getBalanceNumber(earnings, earningToken.decimals)
  const hasEarnings = earnings.toNumber() > 0
 
  const handleHarvestConfirm = async () => {
    setPendingTx(true)
    try {
      await onReward()
      toastSuccess(
        `${t('Harvested')}!`,
        t('Your %symbol% earnings have been sent to your wallet!', { symbol: earningToken.symbol }),
      )
      setPendingTx(false)
    } catch (e) {
      toastError(t('Canceled'), t('Please try again and confirm the transaction.'))
      setPendingTx(false)
    }
  }
  return (
    <Flex flexDirection="column" mb="16px">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column">
          {isLoading ? (
            <Skeleton width="80px" height="48px" />
          ) : (
            <>
              {hasEarnings ? (
                <Balance bold fontSize="20px" decimals={5} value={earningTokenBalance} />
              ) : (
                <Heading color="textDisabled">0</Heading>
              )}
            </>
          )}
        </Flex>
        <Flex>
          <Button
            mt="8px"
            onClick={handleHarvestConfirm}
            isLoading={pendingTx}
            disabled={earningTokenBalance <= 0}
            endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
          >
            {t('Harvest')}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default HarvestActions
