import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import { useAppDispatch } from 'state'
import { fetchFarmUserDataAsync, updateUserStakedBalance, updateUserBalance } from 'state/actions'
import { stake, sousStake, sousStakeBnb } from 'utils/callHelpers'
import { useFarmFromPid } from 'state/hooks'
import { usePIXELfarm, usePIXELpool } from './useContract'

const useStake = (pid: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const farm = useFarmFromPid(pid)
  const masterChefContract = usePIXELfarm(farm.farmAddresses)
  const handleStake = useCallback(
    async (amount: string) => {
      const txHash = await stake(masterChefContract, amount, account, pid)
      dispatch(fetchFarmUserDataAsync(account))
    },
    [account, dispatch, masterChefContract, pid],
  )
  return { onStake: handleStake }
}

export const useSousStake = (sousId: number, isUsingBnb = false) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const sousChefContract = usePIXELpool(sousId)

  const handleStake = useCallback(
    async (amount: string, decimals: number) => {
      if (isUsingBnb) {
        await sousStakeBnb(sousChefContract, amount, account)
      } else {
        await sousStake(sousChefContract, amount, decimals, account)
      }
      dispatch(updateUserStakedBalance(sousId, account))
      dispatch(updateUserBalance(sousId, account))
    },
    [account, dispatch, isUsingBnb, sousChefContract, sousId],
  )

  return { onStake: handleStake }
}

export default useStake
