import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { BIG_TEN } from 'utils/bigNumber'
import { getAddress } from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'

const fetchFarms = async (farmsToFetch: FarmConfig[]) => {
  const data = await Promise.all(
    farmsToFetch.map(async (farmConfig) => {
      const lpAddress = getAddress(farmConfig.lpAddresses)
      const calls = [
        // Balance of token in the LP contract
        {
          address: getAddress(farmConfig.token.address),
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of quote token on LP contract
        {
          address: getAddress(farmConfig.quoteToken.address),
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: getAddress(farmConfig.token.address),
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: getAddress(farmConfig.quoteToken.address),
          name: 'decimals',
        },
        {
          address: lpAddress,
          name: 'balanceOf',
          params: [getAddress(farmConfig.farmAddresses)],
        },
      ]

    
      const [tokenBalanceLP, quoteTokenBalanceLP, lpTotalSupply, tokenDecimals, quoteTokenDecimals, farmBalanceLp] =
      await multicall(erc20, calls)


      const curentRawd = 1
      // Raw amount of token in the LP, including those not staked
      const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
      const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))
      const curentRawdTotal = new BigNumber(curentRawd).div(BIG_TEN.pow(quoteTokenDecimals))
      const lpTokenRatio = new BigNumber(farmBalanceLp).div(new BigNumber(lpTotalSupply))
      const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

      // Total staked in LP, in quote token value
      const lpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2))

      return {
        ...farmConfig,
        tokenAmountTotal: tokenAmountTotal.toJSON(),
        quoteTokenAmountTotal: quoteTokenAmountTotal.toJSON(),
        lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
        tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
        curentRawd: curentRawdTotal.toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
      }
    }),
  )
  return data
}

export default fetchFarms
