import React, { useEffect, useState, useRef } from 'react'
import { Flex, TooltipText, IconButton, useModal, CalculateIcon, Skeleton, useTooltip, Text } from '@pixelweb/uikit'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber } from 'utils/formatBalance'
import { getPoolApr } from 'utils/apr'
import { tokenEarnedPerThousandDollarsCompounding, getRoi } from 'utils/compoundApyHelpers'
import { useBusdPriceFromToken, useGetApiPrice, usePricePXFBusd } from 'state/hooks'
import Balance from 'components/Balance'
import ApyCalculatorModal from 'components/ApyCalculatorModal'
import { Pool } from 'state/types'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { getAddress, getPixelFramAddress } from 'utils/addressHelpers'
import { BASE_EXCHANGE_URL } from 'config'
import useWeb3 from 'hooks/useWeb3'
import { useWeb3React } from '@web3-react/core'
import { getPIXELFarmContract } from 'utils/contractHelpers';
import { usePIXELpool, useERC20 } from 'hooks/useContract'


interface AprRowProps {
  pool: Pool
  isAutoVault?: boolean
  compoundFrequency?: number
  performanceFee?: number
  account?: string
}

const AprRow: React.FC<AprRowProps> = ({ pool, isAutoVault = false, compoundFrequency = 1, performanceFee = 0, account = '' }) => {
  const { t } = useTranslation();
  const { stakingToken, earningToken, userData, harvest } = pool

  const tooltipContent = isAutoVault
    ? t('APY includes compounding, APR doesn’t. This pool’s PIXEL is compounded automatically, so we show APY.')
    : t('This pool’s rewards aren’t compounded automatically, so we show APR')

  const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipContent, { placement: 'bottom-end' })
  const earningTokenPrice = useBusdPriceFromToken(earningToken)
  const earningTokenPriceAsNumber = earningTokenPrice && earningTokenPrice.toNumber()
  const PXFPrice = usePricePXFBusd();
  const [apr, setApr] = useState(0);
  const pixelContract = useERC20(getAddress(stakingToken.address));
  const poolContract = usePIXELpool(pool.sousId);

  // special handling for tokens like tBTC or BIFI where the daily token rewards for $1000 dollars will be less than 0.001 of that token
  const isHighValueToken = Math.round(earningTokenPriceAsNumber / 1000) > 0
  const roundingDecimals = isHighValueToken ? 4 : 2

  const earningsPercentageToDisplay = () => {
    if (isAutoVault) {
      const oneThousandDollarsWorthOfToken = 1000 / earningTokenPriceAsNumber
      const tokenEarnedPerThousand365D = tokenEarnedPerThousandDollarsCompounding({
        numberOfDays: 365,
        farmApr: apr,
        tokenPrice: earningTokenPriceAsNumber,
        roundingDecimals,
        compoundFrequency,
        performanceFee,
      })
      return getRoi({
        amountEarned: tokenEarnedPerThousand365D,
        amountInvested: oneThousandDollarsWorthOfToken,
      })
    }
    return apr
  }

  const apyModalLink =
    stakingToken.address &&
    `${BASE_EXCHANGE_URL}/#/swap?outputCurrency=${stakingToken.address[process.env.REACT_APP_CHAIN_ID]}`

  const [onPresentApyModal] = useModal(
    <ApyCalculatorModal
      tokenPrice={earningTokenPriceAsNumber}
      apr={apr}
      linkLabel={t('Get %symbol%', { symbol: stakingToken.symbol })}
      linkHref={apyModalLink || BASE_EXCHANGE_URL}
      earningTokenSymbol={earningToken.symbol}
      roundingDecimals={isHighValueToken ? 4 : 2}
      compoundFrequency={compoundFrequency}
      performanceFee={performanceFee}
    />,
  )

  useEffect(() => {
    (async () => {
        const rewardRate = await poolContract.methods.rewardRate().call();
        const totalRewardPricePerYear = rewardRate * 86400 * 365 / 1e18;
        const balance = await pixelContract.methods.balanceOf(getAddress(pool.contractAddress)).call() / 1e18;
        const res = balance === 0 ? 0 : (totalRewardPricePerYear / balance) * 100;
        setApr(res)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolContract, PXFPrice])


  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        {tooltipVisible && tooltip}
        <Text fontSize="14px">
          APR
        </Text>
        <Text bold>{apr.toFixed(3)}%</Text>
        {/* {isFinished || !apr ? (
          <div>{apr}%</div>
        ) : (
          <Flex alignItems="center">
            <Balance
              fontSize="16px"
              isDisabled={isFinished}
              value={earningsPercentageToDisplay()}
              decimals={2}
              unit="%"
              bold
            />
          </Flex>
        ) */}
      </Flex>

      <Flex alignItems="center" justifyContent="space-between" mt="10px">
        <Text fontSize="14px">Earn:</Text>
        <Text bold> {stakingToken.symbol}</Text>
      </Flex>

      {/* <Flex alignItems="center" justifyContent="space-between" mt="10px">
        <Text fontSize="14px">{t('Your Stake')}:</Text>
        <Flex alignItems="center">
          {account ? (
            <Balance bold fontSize="20px" decimals={3} value={stakedTokenBalance} />
          ) : (
            <Skeleton width="82px" height="32px" />
          )}
        </Flex>
      </Flex> */}


    </>
  )
}

export default AprRow
