import { useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { useRouterContract } from 'hooks/useContract'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import tokens from 'config/constants/tokens'
import { useAppDispatch } from 'state'
import { setPixelPrice } from '../state/pixelPrice'

export default async () => {
  const dispatch = useAppDispatch()
  const routerContract = useRouterContract();

  useEffect(() => {
    (async () => {
      const pxfAdd = tokens.pxf.address[process.env.REACT_APP_CHAIN_ID];
      const busdAdd = tokens.busd.address[process.env.REACT_APP_CHAIN_ID];
      const num = new BigNumber(1).times(DEFAULT_TOKEN_DECIMAL).toString();
      const val = await routerContract.methods.getAmountsOut(num, [pxfAdd, busdAdd]).call();
      const price_ = new BigNumber(val[1]).div(DEFAULT_TOKEN_DECIMAL);
      dispatch(setPixelPrice(price_));
    })()
  }, [routerContract, dispatch])
}