/* eslint-disable import/order */
import React,{useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { Menu as UikitMenu } from '@pixelweb/uikit'
import { useWeb3React } from '@web3-react/core'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useAuth from 'hooks/useAuth'
// import { usePricePXFBusd } from 'state/hooks'
import useGetPriceData from 'hooks/useGetPriceData'
import tokens from 'config/constants/tokens'
import styled from 'styled-components'
import config from './config'
import configland from './configland'
import configLimited from './configlimited';

const LinksContainer = styled.div`
  position: fixed;
  display: flex;
  width: 160px;
  justify-content: space-between;
  z-index: 2000;
  bottom: 50px;
  left:20px;
  color:white;
  fontSize:28px;
  img{
    width:25px;
    height: 25px;
  }
`

const Menu = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { isDark, toggleTheme } = useTheme()
  
  const bnbPriceData = useGetPriceData(tokens.wbnb.address[process.env.REACT_APP_CHAIN_ID])
  const { currentLanguage, setLanguage, t } = useTranslation();
  const {href} = window.location;
  // const activeIndex = href.indexOf("/land") !== -1 ? 'land' : href.indexOf("/limited") !== -1 ? 'limited' : 'home';
  // const links = activeIndex === 'land' ?  configland(t) : activeIndex === 'limited' ? configLimited(t) : config(t);
  const [toggle, setToggle] = useState(false);
  const [links, setLinks] = useState([])
  const handleToggleNav = (expand: boolean) => {
      setToggle(expand);
  }
  useEffect(() => {
    if (href.indexOf("/land") !== -1 ) {
      setLinks(configland(t))
    } 
    // else if (href.indexOf("/limited") !== -1) {
    //   setLinks(configLimited(t))
    // } 
    else {
      setLinks(config(t))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href])
 
  return (
    <div >
      <UikitMenu 
        style={{position:'relative'}}
        account={account}
        showMenu={false}
        login={login}
        logout={logout}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={'en' || currentLanguage?.code}
        langs={languageList}
        setLang={setLanguage}
        // cakePriceUsd={priceUsd}
        toggleNav={handleToggleNav}
        links={links}
        // profile={{
        //   username: undefined,
        //   image: profile?.nft ? `/images/nfts/${profile.nft?.images.sm}` : undefined,
        //   showPip: !profile?.username,
        // }}
        {...props}
      />
    </div>
  )
}

export default Menu
