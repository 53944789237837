import styled from 'styled-components';

const StyleBase = styled.div``

const BannerStyle = styled(StyleBase)<{isMobile?: boolean }>`
    height: ${({isMobile}) => isMobile ?  '15rem' : '500px' };
    background-image: url('/images/farms_bg.png');
    background-size: auto 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const TitleStyle = styled(StyleBase)<{isMobile?: boolean }>`
    font-size: 60px;
    color: #9ac2ff;
    font-weight: 100;
    margin-bottom: 10px;
`

const DescStyle = styled(StyleBase)<{isMobile?: boolean }>`
    font-size:  ${({isMobile}) => isMobile ?  '1rem' : '16px' };
    color: white;
`

export { BannerStyle, TitleStyle, DescStyle }