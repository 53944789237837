import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from 'web3-eth-contract'
import { ethers } from 'ethers'
import { useAppDispatch } from 'state'
import { updateUserAllowance, fetchFarmUserDataAsync } from 'state/actions'
import { approve } from 'utils/callHelpers'
import { Address } from 'config/constants/types'
import { useERC20 } from 'hooks/useContract'
import { getAddress, getLotteryAddress, getPixelAddress } from 'utils/addressHelpers'
import tokens from 'config/constants/tokens'
import { getBep20Contract } from 'utils/contractHelpers'
import useWeb3 from 'hooks/useWeb3'
import { usePIXELpool, useLottery } from './useContract'


// Approve a Farm
export const useApprove = (lpContract: Contract, lpFarmAddr: Address) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, getAddress(lpFarmAddr), account)
      dispatch(fetchFarmUserDataAsync(account))
      return tx
    } catch (e) {
      return false
    }
  }, [account, dispatch, lpContract, lpFarmAddr])

  return { onApprove: handleApprove }
}

// Approve a Pool
export const useSousApprove = (lpContract: Contract, sousId) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const sousChefContract = usePIXELpool(sousId)

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, sousChefContract.options.address, account)
      dispatch(updateUserAllowance(sousId, account))
      return tx
    } catch (e) {
      return false
    }
  }, [account, dispatch, lpContract, sousChefContract, sousId])

  return { onApprove: handleApprove }
}

// Approve the lottery
export const useLotteryApprove = () => {
  const { account } = useWeb3React()
  const pixelAddress = getPixelAddress()
  const lotteryAddress = getLotteryAddress()
  const pixelContract = useERC20(pixelAddress)
  const handleApprove = useCallback(async () => {
      const tx = await approve(pixelContract, lotteryAddress, account)
      return tx
  }, [lotteryAddress, account, pixelContract])

  return { onApprove: handleApprove }
}

