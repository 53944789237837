import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

// const swapHref = "http://swap.pixelfinance.io/#"
const swapHref = "http://swap.pixelfinance.io/#"  // 正式链

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: 'HOME',
    icon: 'Home',
    href: '/home',
  },
  {
    label: 'TRADE',
    icon: 'Trade',
    items: [
      {
        label: 'EXCHANGE',
        href: `${swapHref}/swap`,
      },
      {
        label: 'LIQUIDITY',
        href: `${swapHref}/pool`,
      },
    ]
  },
  {
    label: 'FARMS',
    icon: 'Farms',
    href: '/farms',
    // disabled: true
  },
  {
    label: 'POOLS',
    icon: 'Pools',
    href: '/pools',
    // disabled: true
  },
  {
    label: 'LOTTERY',
    icon: 'Lottery',
    href: '/lottery',
    disabled: false
  },
  {
    label: 'AIRDROP',
    icon: 'Airdrop',
    href: '/Airdrop',
    // disabled: true
  },
  {
    label: 'PIXEL STARTER',
    icon: 'Starter',
    href: '/starter',
    disabled: false
  }, 
  {
    label: 'PRESALE',
    icon: 'Starter',
    href: '/presale',
    disabled: false
  }
  // {
  //   label: 'MARKET PLACE',
  //   icon: 'PixelMarket',
  //   href: '/pixelMarket',
  //   disabled: false
  // }, 
  // {
  //   label: 'PIXEL CARD LIST',
  //   icon: 'Cardlist',
  //   href: '/pixelList',
  // },
  // {
  //   label: 'FUSION LIST',
  //   icon: 'Fusionlist',
  //   href: '/fusionList',
  // },
  // {
  //   label: 'MORE',
  //   icon: 'More',
  //   items: [
  //   ]
  // },
]

export default config
