import React, { useEffect, useRef } from 'react'
import { Flex, Text, Button, IconButton, AddIcon, MinusIcon, useModal, Skeleton, useTooltip, Box, Heading } from '@pixelweb/uikit'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber } from 'utils/formatBalance'
import { Pool } from 'state/types'
import { useBusdPriceFromToken } from 'state/hooks'
import Balance from 'components/Balance'
import EventBus from 'utils/eventBus';
import NotEnoughTokensModal from '../Modals/NotEnoughTokensModal'
import StakeModal from '../Modals/StakeModal'
// import { chain } from 'lodash'

const InlineText = styled(Text)`
  display: inline;
`
interface StakeActionsProps {
  pool: Pool
  stakingTokenBalance: BigNumber
  stakedBalance: BigNumber
  isBnbPool: boolean
  isStaked: ConstrainBoolean
  isLoading?: boolean
}

const StakeAction: React.FC<StakeActionsProps> = ({
  pool,
  stakingTokenBalance,
  stakedBalance,
  isBnbPool,
  isStaked,
  isLoading = false,
}) => {
  const { stakingToken, stakingLimit, isFinished, userData, earningToken } = pool
  const { t } = useTranslation()
  const stakedTokenBalance = getBalanceNumber(stakedBalance, stakingToken.decimals)
  const stakingTokenPrice = useBusdPriceFromToken(stakingToken);
  const stakingTokenPriceAsNumber = stakingTokenPrice && stakingTokenPrice;
  const lockupData = useRef({})
  // const stakedTokenDollarBalance =
  //   stakingTokenPriceAsNumber &&
  //   getBalanceNumber(stakedBalance.multipliedBy(stakingTokenPriceAsNumber), stakingToken.decimals)

  // const earnings = userData?.pendingReward ? new BigNumber(userData.pendingReward) : BIG_ZERO
  // const earningTokenBalance = getBalanceNumber(earnings, earningToken.decimals)
  // const hasEarnings = earnings.toNumber() > 0
  const [onPresentTokenRequired] = useModal(<NotEnoughTokensModal tokenSymbol={stakingToken.symbol} />);

  const [onPresentStake] = useModal(
    <StakeModal
      isBnbPool={isBnbPool}
      pool={pool}
      stakingTokenBalance={stakingTokenBalance}
      stakingTokenPrice={stakingTokenPriceAsNumber.toNumber()}
    />
  )
  
  const [onPresentUnstake] = useModal(
    <StakeModal
      stakingTokenBalance={stakingTokenBalance}
      isBnbPool={isBnbPool}
      pool={pool}
      stakingTokenPrice={stakingTokenPriceAsNumber.toNumber()}
      isRemovingStake
    />
  )

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('You’ve already staked the maximum amount you can stake in this pool!'),
    { placement: 'bottom' },
  )

  const reachStakingLimit = stakingLimit.gt(0) && userData.stakedBalance.gte(stakingLimit)
  const renderStakeAction = () => {
    return isStaked ? (
      <>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex flexDirection="column">
            <Balance bold fontSize="20px" decimals={3} value={stakedTokenBalance} />
          </Flex>
          <Flex>
            <IconButton variant="tertiary" onClick={onPresentUnstake} mr="6px">
              <MinusIcon color="primary" width="24px" />
            </IconButton>
            {reachStakingLimit ? (
              <span ref={targetRef}>
                <IconButton variant="secondary" disabled>
                  <AddIcon color="textDisabled" width="24px" height="24px" />
                </IconButton>
              </span>
            ) : (
              <IconButton
                variant="tertiary"
                onClick={stakingTokenBalance.gt(0) ? onPresentStake : onPresentTokenRequired}
                disabled={isFinished}
              >
                <AddIcon color="primary" width="24px" height="24px" />
              </IconButton>
            )}
          </Flex>
        </Flex>
      </>
    ) : (
      <Button disabled={isFinished} onClick={stakingTokenBalance.gt(0) ? onPresentStake : onPresentTokenRequired}>
        {t('Stake')}
      </Button>
    )
  }

  return <Flex flexDirection="column">{isLoading ? <Skeleton width="100%" height="52px" /> : renderStakeAction()}</Flex>
}

export default StakeAction
