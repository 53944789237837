import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'PixelFinance',
  description:
    'The most popular AMM on BSC by user count! Earn PXF through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PixelFinance), NFTs, and more, on a platform you can trust.',
  image: '',
}

export const customMeta: { [key: string]: PageMeta } = {
  '/': {
    title: 'Home | PixelFinance',
  },
  '/competition': {
    title: 'Trading Battle | PixelFinance',
  },
  '/prediction': {
    title: 'Prediction | PixelFinance',
  },
  '/farms': {
    title: 'Farms | PixelFinance',
  },
  '/pools': {
    title: 'Pools | PixelFinance',
  },
  '/lottery': {
    title: 'Lottery | PixelFinance',
  }
}
