/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
import React, { createContext, ReactNode, useCallback, useState } from 'react'
import { kebabCase } from 'lodash'
import { Toast, toastTypes } from '@pixelweb/uikit'
import { ToastContextApi } from './types'
import { useDispatch } from 'react-redux'
import {
  push as pushToast,
  remove as removeToast,
  clear as clearToast
} from 'state/actions'


export const ToastsContext = createContext<ToastContextApi>(undefined)

export const ToastsProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastContextApi['toasts']>([])
  const dispatch = useDispatch()
  const push = (toast: Toast) => dispatch(pushToast(toast))

  const toast = useCallback(
    ({ title, description, type }: Omit<Toast, 'id'>) => {
      setToasts((prevToasts) => {
        const id = kebabCase(title)

        // Remove any existing toasts with the same id
        const currentToasts = prevToasts.filter((prevToast) => prevToast.id !== id)
        push({id,title,description,type,...currentToasts})
        return [
          {
            id,
            title,
            description,
            type,
          },
          ...currentToasts,
        ]
      })
    },
    [setToasts],
  )

  const toastError = (title: string, description?: any) => {
    return toast({ title, description, type: toastTypes.DANGER })
  }
  const toastInfo = (title: string, description?: any) => {
    return toast({ title, description, type: toastTypes.INFO })
  }
  const toastSuccess = (title: string, description?: any) => {
    return toast({ title, description, type: toastTypes.SUCCESS })
  }
  const toastWarning = (title: string, description?: any) => {
    return toast({ title, description, type: toastTypes.WARNING })
  }
  const clear = () => {
    dispatch(clearToast())
    setToasts([])
  }
  const remove = (id: string) => {
    dispatch(removeToast(id))
    setToasts((prevToasts) => prevToasts.filter((prevToast) => prevToast.id !== id))
  }

  return (
    <ToastsContext.Provider value={{ toasts, clear, remove, toastError, toastInfo, toastSuccess, toastWarning }}>
      {children}
    </ToastsContext.Provider>
  )
}
