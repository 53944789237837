import React from 'react'
import styled from 'styled-components'
import { Image, useMatchBreakpoints } from '@pixelweb/uikit'
import Page from './layout/Page'

const StyleBase = styled.div``

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledImage = styled(StyleBase)<{isMobile?: boolean }>`
  margin-left: auto;
  margin-right: auto;
  img {
    width: ${({isMobile}) => isMobile ?  '120px' : 'auto' };
    height: 'auto';
  }
`

const PageLoader: React.FC = () => {
  const { isSm, isXs, isLg, isMd } = useMatchBreakpoints();
  const isMobile = isXs || isSm || isLg || isMd ;
  return (
    <Wrapper>
      <StyledImage>
        <img src="/images/loading.gif" alt=''  />
      </StyledImage>
    </Wrapper>
  )
}

export default PageLoader
