import React from 'react'
import flexible from 'utils/flexible';
import ReactDOM from 'react-dom'
import App from './App'
import Providers from './Providers'

flexible(window, document)

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
)
