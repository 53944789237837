import React from 'react'
import styled from 'styled-components'
import { useRouteMatch, Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem, Toggle, Text, Flex, NotificationDot, Link as UiKitLink } from '@pixelweb/uikit'
import { useTranslation } from 'contexts/Localization'

const ButtonText = styled(Text)`
  display: none;
  ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
  }
`

const StyledLink = styled(UiKitLink)`
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`

const PoolTabButtons = ({ stakedOnly, setStakedOnly, hasStakeInFinishedPools }) => {
  const { url, isExact } = useRouteMatch()
  const { t } = useTranslation()

  return (
    <Flex alignItems="center" justifyContent="flex-start" mb="32px">
      <Flex alignItems="center" flexDirection={['row', null, 'row', null]}>
        <Flex mt={['4px', null, 0, null]} mr={['24px', null, '24px', null]} justifyContent="center" alignItems="center">
          <Toggle scale="big" checked={stakedOnly} onChange={() => setStakedOnly((prev) => !prev)} />
          <Text ml="8px" color="#213A6C" style={{ fontSize: '14px', fontWeight: 'bold' }}>
            {t('Staked only')}
          </Text>
        </Flex>
        {/* variant="tabButton" */}
        <ButtonMenu activeIndex={isExact ? 0 : 1} scale="sm" >
          <ButtonMenuItem as={Link} to={`${url}`}>
            Aactive
          </ButtonMenuItem>
          <NotificationDot show={hasStakeInFinishedPools}>
            {/* as={Link} to={`${url}/history`} */}
            <ButtonMenuItem as={Link} to={`${url}/2`}>
              Inactive
            </ButtonMenuItem>
          </NotificationDot>
        </ButtonMenu>
      </Flex>
      {/* <Flex ml="24px" alignItems="center" justifyContent="flex-end">
        <StyledLink external href="">
          <Button px={['14px', null, null, null, '20px']} variant="subtle">
            <ButtonText color="backgroundAlt" bold fontSize="16px">
              {t('Help')}
            </ButtonText>
            <HelpIcon color="backgroundAlt" ml={[null, null, null, 0, '6px']} />
          </Button>
        </StyledLink>
      </Flex> */}
    </Flex>
  )
}

export default PoolTabButtons
