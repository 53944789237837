import React, { useState, useEffect } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { getBalanceNumber } from 'utils/formatBalance'
import { useTranslation } from 'contexts/Localization'
import {
  Flex,
  MetamaskIcon,
  Text,
  TooltipText,
  LinkExternal,
  TimerIcon,
  Skeleton,
  useTooltip,
  Button,
} from '@pixelweb/uikit'
import { getPIXELPoolContract } from 'utils/contractHelpers'
import useWeb3 from 'hooks/useWeb3'
import { BASE_BSC_SCAN_URL, BASE_URL, DEFAULT_TOKEN_DECIMAL } from 'config'
import { useBlock } from 'state/hooks'
import { Pool } from 'state/types'
import { getAddress } from 'utils/addressHelpers'
import { registerToken } from 'utils/wallet'
import Balance from 'components/Balance'

interface ExpandedFooterProps {
  pool: Pool
  account: string
  isAutoVault?: boolean
}

const ExpandedWrapper = styled(Flex)`
  /* svg {
    height: 14px;
    width: 14px;
  } */
`

const StyledLinkExternal = styled(LinkExternal)`
  color: white;
  font-weight: 400;
`

const ExpandedFooter: React.FC<ExpandedFooterProps> = ({ pool, account, isAutoVault = false }) => {
  const { t } = useTranslation()
  const { stakingToken, earningToken, totalStaked, startBlock, endBlock, isFinished, contractAddress, sousId } = pool
  const tokenAddress = earningToken.address ? getAddress(earningToken.address) : ''
  const poolContractAddress = getAddress(contractAddress)
  const imageSrc = `${BASE_URL}/images/tokens/${earningToken.symbol.toLowerCase()}.png`
  const isMetaMaskInScope = !!(window as WindowChain).ethereum?.isMetaMask
  const isManualCakePool = sousId === 0
  const [lpTotal, setLpTotal] = useState(0);
  const shouldShowBlockCountdown = Boolean(!isFinished && startBlock && endBlock)
  // const blocksUntilStart = Math.max(startBlock - currentBlock, 0)
  // const blocksRemaining = Math.max(endBlock - currentBlock, 0)
  // const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0
  const WEB3 = useWeb3();
  const poolContract = getPIXELPoolContract(pool.sousId, WEB3);

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('Subtracted automatically from each yield harvest and burned.'),
    { placement: 'bottom-end' },
  )

  const isBscScan = process.env.REACT_APP_CHAIN_ID === '56';
  const bscScanAddress = isBscScan ? `${BASE_BSC_SCAN_URL}/token/${tokenAddress}` : `https://testnet.bscscan.com/token/${tokenAddress}`

  useEffect(() => {
    const getLpTotal = async () => {
      const LPTotal = await poolContract.methods.totalSupply().call();
      const val = Number(new BigNumber(LPTotal).div(DEFAULT_TOKEN_DECIMAL).toFixed(8))
      setLpTotal(val);
    }
    getLpTotal();
  }, [poolContract])

  const getTotalStakedBalance = () => {

    if (isManualCakePool) {
      const manualCakeTotalMinusAutoVault = new BigNumber(totalStaked)
      return getBalanceNumber(manualCakeTotalMinusAutoVault, stakingToken.decimals)
    }
    return getBalanceNumber(totalStaked, stakingToken.decimals)
  }

  return (
    <ExpandedWrapper flexDirection="column">

      <Flex mb="2px" justifyContent="space-between" alignItems="center">
        <Text small>Deposit:</Text>
        <StyledLinkExternal href={BASE_URL}>
          PXF
        </StyledLinkExternal>
      </Flex>
      <Flex justifyContent="space-between">
        <Text fontSize="14px" color='white'>{t('Total Liquidity')}:</Text>
        <Text fontSize="14px" color='white'>{lpTotal}</Text>
      </Flex>
      <Flex justifyContent="flex-end">
        <StyledLinkExternal small href={bscScanAddress}>
          View BSC Scan
        </StyledLinkExternal>
      </Flex>

      {/* <Flex mb="2px" justifyContent="space-between" alignItems="center">
        <Text small>{t('Total staked')}:</Text>
        <Flex alignItems="flex-start">
          {totalStaked ? (
            <>
              <Balance fontSize="14px" fontWeight="bold" value={getTotalStakedBalance()} />
              <Text ml="4px" fontSize="14px">
                {stakingToken.symbol}
              </Text>
            </>
          ) : (
            <Skeleton width="90px" height="21px" />
          )}
        </Flex>
      </Flex> */}
      {/* {shouldShowBlockCountdown && ( */}
      {/* <Flex mb="2px" justifyContent="space-between" alignItems="center">
        <Text small>{hasPoolStarted ? t('End') : t('Start')}:</Text>
        <Flex alignItems="center">
          {blocksRemaining || blocksUntilStart ? (
              <Balance
                color="primary"
                fontSize="14px"
                fontWeight="bold"
                value={hasPoolStarted ? blocksRemaining : blocksUntilStart}
                decimals={0}
              />
            ) : (
              <Skeleton width="54px" height="21px" />
            )}
          <Text ml="4px" color="primary" small textTransform="lowercase">
              {t('Blocks')}
            </Text>
          <TimerIcon ml="4px" color="primary" />
        </Flex>
      </Flex> */}
      {/* )} */}
      {/* <Flex mb="2px" justifyContent="flex-end">
        <LinkExternal bold={false} small href={earningToken.projectLink}>
          {t('View Project Site')}
        </LinkExternal>
      </Flex> */}
      {/* {poolContractAddress && (
        <Flex mb="2px" justifyContent="flex-end">
          <LinkExternal
            bold={false}
            small
            href={`${BASE_BSC_SCAN_URL}/address/${poolContractAddress}`}
          >
            {t('View Contract')}
          </LinkExternal>
        </Flex>
      )} */}
      {/* {account && isMetaMaskInScope && tokenAddress && (
        <Flex justifyContent="flex-end">
          <Button
            variant="text"
            p="0"
            height="auto"
            onClick={() => registerToken(tokenAddress, earningToken.symbol, earningToken.decimals, imageSrc)}
          >
            <Text color="primary" fontSize="14px">
              {t('Add to Metamask')}
            </Text>
            <MetamaskIcon ml="4px" />
          </Button>
        </Flex>
      )} */}
    </ExpandedWrapper>
  )
}

export default React.memo(ExpandedFooter)
