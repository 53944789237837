import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 1,
    stakingToken: tokens.pxf,
    earningToken: tokens.pxf,
    contractAddress: {
      56: '0xE10fac38B5Ed69aDc9b38e9A2bF19398FEF681eD',
      97: '0xb3B28D08f97f7baE0D9A9eF386cb3b92e23D2f6c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerSecond: 0.052083333333333336,
    sortOrder: 1,
    isFinished: false,
  }
]

export default pools
