import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Heading, useMatchBreakpoints } from '@pixelweb/uikit'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import usePersistState from 'hooks/usePersistState'
import { usePools } from 'state/hooks'
import FlexLayout from 'components/layout/Flex'
import Page from 'components/layout/Page'
import PageHeader from 'components/PageHeader'
import { BannerStyle, TitleStyle, DescStyle } from './PoolndexStyled'; 
import PoolCard from './components/PoolCard'
import PoolTabButtons from './components/PoolTabButtons'

const NUMBER_OF_POOLS_VISIBLE = 12

const Pools: React.FC = () => {
  const { path } = useRouteMatch()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const pools = usePools(account)
  const [stakedOnly, setStakedOnly] = usePersistState(false, 'pixel_pool_staked')
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
  const [observerIsSet, setObserverIsSet] = useState(false)
  const loadMoreRef = useRef<HTMLDivElement>(null)
  const { isSm, isXs, isLg, isMd, isXl } = useMatchBreakpoints();
  const isMobile = isXs || isSm || isLg || isMd;

  const [finishedPools, openPools] = useMemo(() => partition(pools, (pool) => pool.isFinished), [pools]);

  const stakedOnlyFinishedPools = useMemo(
    () => finishedPools.filter((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
    [finishedPools],
  )
  const stakedOnlyOpenPools = useMemo(
    () => openPools.filter((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
    [openPools],
  )
  const hasStakeInFinishedPools = stakedOnlyFinishedPools?.length > 0;

  useEffect(() => {
    const showMorePools = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setNumberOfPoolsVisible((poolsCurrentlyVisible) => poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE)
      }
    }

    if (!observerIsSet) {
      const loadMoreObserver = new IntersectionObserver(showMorePools, {
        rootMargin: '0px',
        threshold: 1,
      })
      loadMoreObserver.observe(loadMoreRef.current)
      setObserverIsSet(true)
    }
  }, [observerIsSet])

  const poollength = orderBy(stakedOnlyOpenPools, ['sortOrder']).slice(0, numberOfPoolsVisible)?.length;
  const openPoolsLength = orderBy(openPools, ['sortOrder']).slice(0, numberOfPoolsVisible)?.length;
  
  return (
    <>
      <BannerStyle isMobile={isMobile}>
          <TitleStyle>POOLS</TitleStyle>
          <DescStyle>Stake tokens to earn Pixel tokens.</DescStyle>
      </BannerStyle>
      <Page>
        <PoolTabButtons
          stakedOnly={stakedOnly}
          setStakedOnly={setStakedOnly}
          hasStakeInFinishedPools={hasStakeInFinishedPools}
        />
        <FlexLayout style={{ justifyContent: isMobile || (!openPoolsLength) ? 'center' : 'flex-start', alignItems: !poollength ? 'center' : 'flex-start' }}>
          <Route exact path={`${path}`}>
            {stakedOnly
              ? orderBy(stakedOnlyOpenPools, ['sortOrder'])
                .slice(0, numberOfPoolsVisible)
                .map((pool) => <PoolCard key={pool.sousId} pool={pool} account={account} />)
              : orderBy(openPools, ['sortOrder'])
                .slice(0, numberOfPoolsVisible)
                .map((pool) => <PoolCard key={pool.sousId} pool={pool} account={account} />)}
 
          </Route>
          <Route exact path={`${path}/2`}>
            {stakedOnly
              ? orderBy(stakedOnlyOpenPools, ['sortOrder'])
                .slice(0, numberOfPoolsVisible)
                .map((pool) => <PoolCard key={pool.sousId} pool={pool} account={account} />)
              : orderBy(openPools, ['sortOrder'])
                .slice(0, numberOfPoolsVisible)
                .map((pool) => <PoolCard key={pool.sousId} pool={pool} account={account} />)}
          </Route>
          {
            ((stakedOnly && !stakedOnlyOpenPools?.length) || (!stakedOnly && !openPools?.length)) && <img alt='egg' style={{ width: "20px" }} src='/images/pools/egg.png' />
          }
        </FlexLayout>
        <div ref={loadMoreRef} />
      </Page>
    </>
  )
}

export default Pools
