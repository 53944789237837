/* eslint-disable consistent-return */
import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'

export const useCheckLockedOfFarm = (contract, pid) => {
    const { account } = useWeb3React();
    const checkLockedStatus = useCallback( async () => {
        const lockedStatus =  await contract.methods.isLocked(pid, account).call();
        let expireTime = null;  let totalScale  = null; let fee  = null;
        if (lockedStatus) {
            expireTime = await contract.methods.lastStakeLockTime(pid, account).call();
            totalScale = await contract.methods.totalScale().call();
            fee = await contract.methods.fee().call();
            return {
                lockedStatus,
                expireTime,
                restLockedTime: parseInt( ( (expireTime*1000 - Date.now()) / (3600 * 1000)).toString()),
                totalScale,
                fee
            };
        }
        return lockedStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract, account]);
    if (account) {
        return { checkLockedStatus }
    }
    return { checkLockedStatus: null }
}

export const useCheckLockedOfPool = (contract, pid) => {
    const { account } = useWeb3React()
    const checkLockedStatus = useCallback( async () => {
        const lockedStatus =  await contract.methods.isLocked(account).call();
        let expireTime = null;  let totalScale  = null; let fee  = null;
        if (lockedStatus) {
            expireTime = await contract.methods.lastStakeLockTime(account).call();
            totalScale = await contract.methods.totalScale().call();
            fee = await contract.methods.fee().call();
            return {
                lockedStatus,
                expireTime,
                restLockedTime: parseInt( ( (expireTime*1000 - Date.now()) / (3600 * 1000)).toString()),
                totalScale,
                fee
            };
        }
        return lockedStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract, account]);
     if (account) {
        return { checkLockedStatus }
    }
    return { checkLockedStatus: null }
}
 

 