export default {
  router: {
    56: '0x0ad5C283131fec3f3DdE4FA958048858e382eAFF',
    97: '0xe393380A92db9AC698A2ebaE3d2f8963268a16E2',
  },
  lottery: {
    56: '0x62ec4B3eFEA171e9D9bD15FE9027B2b12cc63053',
    // 97: '0x7250079DC25439B1d6DA3aBfF4ABA18e092f9d75'
    // 97: '0x0d881FF21c32C8E01B235Fe42F0A29ad02FEFCAd'
    97: '0x8FA6b31533F3D1d2636e27f5345AA0A8C49AEF60'
  },
  lotteryNFT: {
    // 97: '0x0d881FF21c32C8E01B235Fe42F0A29ad02FEFCAd',
    97: '0xf102eCc49bA92265137c1805C089b454d07167A2',
    56: '0xff0997392A63Ca7e5eaB82F22077eF848cb468e2',
  },
  multiCall: {
    56: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c',
    97: '0xff0997392A63Ca7e5eaB82F22077eF848cb468e2',
  },
  pixelFram: {
    56: '0x040270e09bf6F7e1554c85C48C6dcA223db9b008',
    // 56: '0xA31B302209C7Fc8086a3D05D8f98e1376CD08926',
    97: '0x4229fE35B17e7Fdc16Ee4f65e4079B71466837A1',
  },
  presale: {
    56: '0xD1B4ae503Db70Cd584c936BAf6b415F585d2c219',
    // 97: '0x685853ce193De6BfB297a8eFFFE63aa096fC27f0'
    // 97: '0x73Fc1f85AB315F9125a584d3c7eA86645D4CaE64'
    97: '0x19B71900C5018415B45C7755a5C24e1740D0695b'
  },
  starter: {
    56: '',
    97: '0x0f4a308D47A2e2076761b45c236efd6711D3896C',
  },
  nft: {
    56: '0xa5976974d5A340Cc0a06aA41B3d8d4D6780F55Ba',
    97: '0x844C604f3B643D66Fe2014DfD1c9a44c822c32da'
  },
  exchange: {
    56: '0x6fb8eE4e23DDC8EE2D5FC2D5abEb4489dEb8951e',
    97: '0xEC14357956082De47b9e69790d7b61f2dbDD7eAE'
  },
  singlePool: {
    56: '0x202092336Ec4896e370065D226F83257f02E9Af3',
    97: '0x56d39571504A4F15D6431A6116A19Ab3c153469d'
  },
  fusionPool: {
    56: '0x7f0A823a4D75E0cC054fd377e72476e4dF7630d6',
    97: '0xc283a9587ddd80A28750BE4A8B0743410F4d26BC'
  },
  pxfToken: {
    56: '0x6a4792d10BEe30Ff25d1d8F9aA3721d204d88134',
    97: '0x69b4d8350eb2c379fd164cb14225296b67ebc214'
  },
  airdrop: {
    56: '0x75732Bbf9B0B59A731352231D0dE319085B7207a',
    // 97: '0x2F5Bc99DC174E19f21461A0EE07A081c42De9829'
    // 97: '0xE43a51Dd90D2936Cbd3d9eD5e5cB6F1a91f3e88b'
    97: ''
  },
  limited: {
    56: '0x6a4792d10BEe30Ff25d1d8F9aA3721d204d88134',
    97: '0x361be0E80C12bC068E744C6905ac3d5ec03b715A',
    // 97: '0x44058D610C8C017bD9054E74435682448aC30995'
  },
  busdToken: {
    56: '0x6a4792d10BEe30Ff25d1d8F9aA3721d204d88134',
    97: '0xF1c9be9A08CDdA25E06AE0dAa0f47370bee52998'
  }
}

