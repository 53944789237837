import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 10 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeStats.tsx = 20 (40 - Amount sent to burn pool)

export const CAKE_PER_BLOCK = new BigNumber(40)
export const BLOCKS_PER_YEAR_ = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const YEAR_SECOND = new BigNumber(60 * 60 * 24 * 365)
// test
// export const BASE_URL = 'http://swap.gotoup.space/#/swap'
// export const BASE_EXCHANGE_URL = 'http://swap.gotoup.space'

// 主网地址
export const BASE_URL = 'http://swap.pixelfinance.io/#/swap'
export const BASE_EXCHANGE_URL = 'http://swap.pixelfinance.io'
export const BASE_NFT_CARD_URL = 'https://pixelland.s3.ap-northeast-1.amazonaws.com/'


export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/#/add`
export const BASE_LIQUIDITY_POOL_URL = `${BASE_EXCHANGE_URL}/#/pool`
export const BASE_BSC_SCAN_URL = 'https://bscscan.com'
export const LOTTERY_MAX_NUMBER_OF_TICKETS = 45
export const LOTTERY_TICKET_PRICE = 10
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
