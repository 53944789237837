/* eslint-disable no-irregular-whitespace */
import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pixelweb/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  html: font-size: 16px!important;
  button{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-radius: 10px!important;
  }
  input {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    highlight-color: rgba(0, 0, 0, 0);
  }
  * {
    font-family: 'Kanit', sans-serif;
  }
  @font-face {
    font-family: 'myFont';
    src: url(../../public/font/pixel[1].ttf);
  }
  body {
    background: linear-gradient(180deg, #c4eafc 0%, #dafef8 100%);

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
