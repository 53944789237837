import { useMemo } from 'react'
import useWeb3 from 'hooks/useWeb3'
import {
  getRouterContract,
  getBep20Contract,
  getLotteryContract,
  getLotteryTicketContract,
  getErc721Contract,
  getPIXELPoolContract,
  getPIXELFarmContract,
  getPreSaleContract,
  getStarterContract,
  getNftContract,
  getLimitedContract
} from 'utils/contractHelpers'
import { getAddress } from 'utils/addressHelpers'
import { Address } from 'config/constants/types'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getBep20Contract(address, web3), [address, web3])
}

// /**
//  * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
//  */
export const useERC721 = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getErc721Contract(address, web3), [address, web3])
}

export const useRouterContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getRouterContract(web3), [web3])
}

export const useLottery = () => {
  const web3 = useWeb3()
  return useMemo(() => getLotteryContract(web3), [web3])
}

export const useLimited = () => {
  const web3 = useWeb3()
  return useMemo(() => getLimitedContract(web3), [web3])
}

export const useNft = () => {
  const web3 = useWeb3()
  return useMemo(() => getNftContract(web3), [web3])
}

export const useLotteryTicket = () => {
  const web3 = useWeb3()
  return useMemo(() => getLotteryTicketContract(web3), [web3])
}

export const usePIXELpool = (id) => {
  const web3 = useWeb3()
  return useMemo(() => getPIXELPoolContract(id, web3), [id, web3])
}

export const usePIXELfarm = (address: Address) => {
  const web3 = useWeb3()
  const addressStr = getAddress(address)
  return useMemo(() => getPIXELFarmContract(addressStr, web3), [addressStr, web3])
}

export const usePreSale = (address: Address) => {
  const web3 = useWeb3()
  const addressOfpreSale = getAddress(address);
  return useMemo(() => getPreSaleContract(addressOfpreSale, web3), [addressOfpreSale, web3])
}

export const useStarter = () => {
  const web3 = useWeb3()
  return useMemo(() => getStarterContract(web3), [web3])
}