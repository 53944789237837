import { ethers } from 'ethers'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import web3NoAccount from 'utils/web3'
import { poolsConfig } from 'config/constants'
import { PoolCategory } from 'config/constants/types'
import MultiCallAbi from 'config/abi/Multicall.json'

// Addresses
import {
  getAddress,
  getRouterAddress,
  getLotteryAddress,
  getAirDropAddress,
  getLotteryTicketAddress,
  getMulticallAddress,
  getContractV2,
  getStarterAddress,
  getNftAddress,
  getNftExchangeAddress,
  getSinglePoolAddress,
  getFusionPoolAddress,
  getPxfTokenAddress,
  getLimitedAddress,
  getBUSDAddress,
} from 'utils/addressHelpers'

// ABI'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import routerAbi from 'config/abi/IUniswapV2Router02.json'
import lotteryAbi from 'config/abi/lottery.json'
import airDropAbi from 'config/abi/Airdrop.json'
import nftAbi from 'config/abi/nft.json'
import nftExchangeAbi from 'config/abi/nft-exchange.json'
import singlePoolAbi from 'config/abi/single-pool.json'
import lotteryTicketAbi from 'config/abi/lotteryNft.json'
import pixelFramAbi from 'config/abi/PixelMasterChefABI.json'
import pixelPoolAbi from 'config/abi/PXF-Pool.json'
import preSaleAbi from 'config/abi/preSaleAbi.json'
import starterABI from 'config/abi/StarterABI.json'
import pxfTokenAbi from 'config/abi/pxfToken.json'
import limitedAbi from 'config/abi/limitedAbi.json'

const getContract = (abi: any, address: string, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccount
  return new _web3.eth.Contract(abi as unknown as AbiItem, address)
}
export const getBep20Contract = (address: string, web3?: Web3) => {
  return getContract(bep20Abi, address, web3)
}

export const getBUSDContract = (web3?: Web3) => {
  return getContract(bep20Abi, getBUSDAddress(), web3)
}

export const getErc721Contract = (address: string, web3?: Web3) => {
  return getContract(erc721Abi, address, web3)
}
export const getLpContract = (address: string, web3?: Web3) => {
  return getContract(lpTokenAbi, address, web3)
}
export const getPIXELPoolContract = (id: number, web3?: Web3) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(pixelPoolAbi, getAddress(config.contractAddress), web3)
}

export const getRouterContract = (web3?: Web3) => {
  return getContract(routerAbi, getRouterAddress(), web3)
}

export const getLotteryContract = (web3?: Web3) => {
  return getContract(lotteryAbi, getLotteryAddress(), web3)
}

export const getLimitedContract = (web3?: Web3) => {
  return getContract(limitedAbi, getLimitedAddress(), web3)
}

export const getAirDropContract = (web3?: Web3) => {
  return getContract(airDropAbi, getAirDropAddress(), web3)
}

export const getNftExchangeContract = (web3?: Web3) => {
  return getContract(nftExchangeAbi, getNftExchangeAddress(), web3)
}

export const getPxfTokenContract = (web3?: Web3) => {
  return getContract(pxfTokenAbi, getPxfTokenAddress(), web3)
}

export const getSinglePoolContract = (web3?: Web3) => {
  return getContract(singlePoolAbi, getSinglePoolAddress(), web3)
}

export const getFusionPoolContract = (web3?: Web3) => {
  return getContract(singlePoolAbi, getFusionPoolAddress(), web3)
}

export const getNftContract = (web3?: Web3) => {
  return getContract(nftAbi, getNftAddress(), web3)
}

export const getLotteryTicketContract = (web3?: Web3) => {
  return getContract(lotteryTicketAbi, getLotteryTicketAddress(), web3)
}

export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContractV2(MultiCallAbi, getMulticallAddress(), signer)
}

export const getPIXELFarmContract = (address: string, web3?: Web3) => {
  // return getContract(pixelFramAbi, getPixelFramAddress(), web3)
  return getContract(pixelFramAbi, address, web3)
}

export const getPreSaleContract = (address: string, web3) => {
  return getContract(preSaleAbi, address, web3);
}

export const getStarterContract = (web3?: Web3) => {
  return getContract(starterABI, getStarterAddress(), web3)
}