/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, useEffect } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS } from '@pixelweb/uikit'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { useFetchPriceList, useFetchPublicData } from 'state/hooks'
import getPXFprice from 'utils/getPXFprice'
import { useAppDispatch } from 'state'
import { useUpdate } from 'ahooks';
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
import EasterEgg from './components/EasterEgg'
import Pools from './views/Pools'
import history from './routerHistory'
import './font/pixel.ttf'
import './font.css'


// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Lottery = lazy(() => import('./views/Lottery'))
const NotFound = lazy(() => import('./views/NotFound'))
const Presale = lazy(() => import('./views/Presale'))
const PixelMarket = lazy(() => import('./views/PixelMarket'))
const PixelList = lazy(() => import('./views/PixelList'))
const FusionList = lazy(() => import('./views/FusionList'))
const BuyCards = lazy(() => import('./views/BuyCards'))
const SinglePool = lazy(() => import('./views/SinglePool'))
const FusionPool = lazy(() => import('./views/FusionPool'))
const LandHome = lazy(() => import('./views/LandHome'))
const Airdrop = lazy(() => import('./views/Airdrop'))
const Limited = lazy(() => import('./views/Limited')) 
const CardApplication = lazy(() => import('./views/Limited/components/CardApplication'))
const WinningResult = lazy(() => import('./views/Limited/components/WinningResult'))
const CardBox = lazy(() => import('./views/Limited/components/CardBox'))

 
// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const update = useUpdate()
  if (window.ethereum) {
    getPXFprice()
    useEagerConnect()
    useFetchPublicData()
    useFetchPriceList();
  }
  useEffect(() => { 
    if(window.ethereum) {
      window.ethereum.on('chainChanged',async (chainid) => { 
        await sessionStorage.setItem('chainid', chainid)
        update()
      })
    }
    setTimeout(() => {
      console.clear()
    }, 1000);
  }, [])
  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu id="menu">
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Redirect exact from="/" to="/home" />
            <Route exact path="/Airdrop">
              <Airdrop />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/pools">
              <Pools />
            </Route>
            <Route exact path="/lottery">
              <Lottery />
            </Route>  
            <Route exact path="/land/home">
              <LandHome />
            </Route>
            <Route exact path="/land">
              <LandHome />
            </Route>
            <Route exact path="/land/pixelMarket">
              <PixelMarket />
            </Route>
            <Route exact path="/land/pixelList">
              <PixelList />
            </Route>
            <Route path="/land/buycards"> 
              <BuyCards />
            </Route>
            <Route exact path="/land/fusionList">
              <FusionList />
            </Route> 
            <Route exact path="/land/singlePool">
              <SinglePool />
            </Route>
            <Route exact path="/land/fusionPool">
              <FusionPool />
            </Route>
            <Route exact path="/presale">
              <Presale />
            </Route> 
            {/* <Route exact path="/limited/home">
              <Limited />
            </Route>
            <Route exact path="/limited/cardapplication">
              <CardApplication />
            </Route>
            <Route exact path="/limited/WinningResult">
              <WinningResult />
            </Route>
            <Route exact path="/limited/cardBox">
              <CardBox />
            </Route> */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)

