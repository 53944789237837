import BigNumber from 'bignumber.js'
import React, { useState, useRef, useEffect} from 'react'
import { CardBody, Flex, Text, CardRibbon, useTooltip, useMatchBreakpoints } from '@pixelweb/uikit'
import UnlockButton from 'components/UnlockButton'
import { useTranslation } from 'contexts/Localization'
import { BIG_ZERO } from 'utils/bigNumber'
import { Pool } from 'state/types'
import { useCheckLockedOfPool } from 'hooks/useCheckLocked';
import { usePIXELpool, useERC20 } from 'hooks/useContract'
import EventBus from 'utils/eventBus';
import AprRow from './AprRow'
import { StyledCard, StyledCardInner } from './StyledCard'
import CardFooter from './CardFooter'
import StyledCardHeaderNew from './StyledCardHeaderNew'
import CardActions from './CardActions'

const PoolCard: React.FC<{ pool: Pool; account: string }> = ({ pool, account }) => {
  
  const { sousId, stakingToken, earningToken, isFinished, userData, harvest } = pool
  const { t } = useTranslation()
  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const earnings = userData?.pendingReward ? new BigNumber(userData.pendingReward) : BIG_ZERO;
  const accountHasStakedBalance = stakedBalance.gt(0);
  const isApproved = account && stakedBalance && stakedBalance.isGreaterThan(0);
  const poolContract = usePIXELpool(pool.sousId);

  const { checkLockedStatus } = useCheckLockedOfPool(poolContract, sousId);
  let timer = null;
  const expireDate = useRef(0);
  const [ restLockTime, setRestLockTime ] = useState(0);
  const HALF_HOUR = 1800 * 1000;
  const showLockUp = useRef(true);
  const [duringLockupDate, setDuringLockUpDate] = useState(false);
  const { isSm } = useMatchBreakpoints()
  const isMobile = isSm
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('0.3% fee will be charged if you unlock the token within 3 days of depositing it. After that, you can unlock it at 0%.'),
    { placement: 'top-end', tooltipOffset: [20, 10] },
  )
  const startToUpdateLockTime = () => {
      const resTime = parseInt( ((expireDate.current * 1000 - Date.now()) / (3600 * 1000  )).toString());
      setRestLockTime(resTime);
      expireDate.current -= HALF_HOUR;
      if (expireDate.current <=0) {
          setRestLockTime(0);
          clearInterval(timer);
      }
  };

  const checkIsLocked = async(result)=>{
    if (result) {
        clearInterval(timer);
        setDuringLockUpDate(true);
        const { expireTime, restLockedTime }  = result;
        setRestLockTime(restLockedTime);
        expireDate.current = expireTime;
        showLockUp.current = false;
        timer = setInterval(() => {
            startToUpdateLockTime();
        }, HALF_HOUR)
    } else {
        showLockUp.current = true;
        setDuringLockUpDate(false);
    }
  }
  
  useEffect(()=>{
   const check = async () => {
      if (typeof checkLockedStatus === 'function' && isApproved) {
        const result = await checkLockedStatus();
        if(result) {
          checkIsLocked(result);
        } else {
          showLockUp.current = false;
          setRestLockTime(0);
        }
      }
    }
    check();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[checkLockedStatus , account, isApproved]);

  useEffect(() => {
      EventBus.on("resetLockTimeStake", (res) => {
          checkIsLocked(res);
      })
      EventBus.on("resetLockTimeUnstake", (res) => {
          if (res) {
            checkIsLocked(res);
          } else {
            clearInterval(timer);
            showLockUp.current = false;
            setRestLockTime(0)
          }
      })
     return ()=> {
       clearInterval(timer);
       EventBus.off("resetLockTimeStake");
       EventBus.off("resetLockTimeUnstake");
     }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 

  return (
    <StyledCard
      style={{ maxWidth: isMobile ? '100%' : '31.5%' }}
      isFinished={isFinished && sousId !== 0}
      ribbon={isFinished && <CardRibbon variantColor="textDisabled" text={t('Finished')} />}
    >
      <StyledCardInner>
        <StyledCardHeaderNew
            pool={pool}
            isStaking={accountHasStakedBalance}
            earningTokenSymbol={earningToken.symbol}
            stakingTokenSymbol={stakingToken.symbol}
            isFinished={isFinished && sousId !== 0}
            account={account}
        />
        
        <CardBody style={{ padding: '0 24px 24px 24px' }}>
          <AprRow pool={pool} account={account} />
            {
              showLockUp.current ? 
                <div style={{textAlign: 'left'}}  ref={targetRef}>
                  <div><Text fontSize="14px">{t('Harvest Lockup')}:</Text></div>
                  <div><Text>{t("0.3% unstaking fee if withdrawn within 72h")}</Text></div>
                  {tooltipVisible && tooltip}
                </div>:
                <div style={{textAlign: 'left', marginBottom: '10px'}} ref={targetRef}>
                  <div><Text fontSize="14px">{t('Harvest Lockup')}:</Text></div>
                  <div><Text>0.3% unstaking fee until {restLockTime} Hour(s)</Text> </div>
                  {tooltipVisible && tooltip}
                </div>
            }
          <Flex flexDirection="column">
            {account ? (
              <CardActions pool={pool} stakedBalance={stakedBalance} />
            ) : (
              <>
                {/* <Text mb="10px" textTransform="uppercase" fontSize="12px" color="textSubtle" bold>
                  {t('Start earning')}
                </Text> */}
                <UnlockButton />
              </>
            )}
          </Flex>
        </CardBody>
        <CardFooter pool={pool} account={account} />
      </StyledCardInner>
    </StyledCard>
  )
}

export default PoolCard
