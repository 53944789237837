/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, getDefaultMiddleware } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import { useRouterContract } from 'hooks/useContract'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import tokens from 'config/constants/tokens'
import { BlockState } from '../types'

type pixelPriceState = {
  price: BigNumber
}
const initialState:  pixelPriceState= { price: new BigNumber(0) }

export const pixelPriceSlice = createSlice({
  name: 'PixelPrice',
  initialState,
  reducers: {
    setPixelPrice: (state, action: PayloadAction<BigNumber>) => {
      state.price = action.payload
    },
  },
})

// Actions
export const { setPixelPrice } = pixelPriceSlice.actions

export default pixelPriceSlice.reducer



 


