const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
    decimals: 18,
  },
  pxf: {
    symbol: 'PXF',
    address: {
      56: '0x6a4792d10BEe30Ff25d1d8F9aA3721d204d88134',
      97: '0x69B4d8350EB2c379fd164cb14225296B67ebC214',
    },
    decimals: 18,
    projectLink: '',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      97: '0xeD1829f3Ee4C282FeD8811Bca1DC28645FFF1bA0',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      56: '0x55d398326f99059ff775485246999027b3197955',
      97: '0xC0C0c4E4A1C2997265D067a73b0592cc84db411c',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
  wbnb: {
    symbol: 'wBNB',
    address: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      97: '0xA75cCf1E551e5A70F5fBE35D0f19BF3A2d3144a7',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
}

export default tokens
 