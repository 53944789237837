import { TranslatableText } from 'state/types'

export interface Address { // 97是测试链，56是正式链，我们现在目前调试都是97
  97?: string
  56: string
}

export interface Token {
  symbol: string
  address?: Address
  decimals?: number
  projectLink?: string
}

export enum PoolIds {
  poolBasic = 'poolBasic',
  poolUnlimited = 'poolUnlimited',
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance', // Pools using native BNB behave differently than pools using a token
  'AUTO' = 'Auto',
  'POOL_LP' = 'Poollp',
}

export enum FarmLPCategory {
  'PANCANCAKE' = 'PANCANCAKE',
  'PXF' = 'PXF',
}

export interface FarmConfig {
  pid: number
  lpSymbol: string
  lpAddresses: Address
  farmAddresses: Address
  token: Token
  quoteToken: Token
  earningTokens: Token[]
  tokenPerSecond: number
  lpCategory: FarmLPCategory
  multiplier?: string
  isCommunity?: boolean
  tokens: string
  lpToken: any
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
}

export interface PoolConfig {
  sousId: number
  earningToken: Token
  stakingToken: Token
  contractAddress: Address
  poolCategory: PoolCategory
  tokenPerSecond: number
  sortOrder?: number
  harvest?: boolean
  isFinished?: boolean
  enableEmergencyWithdraw?: boolean
}

export type PageMeta = {
  title: string
  description?: string
  image?: string
}
